<template>
    <div>
        <p v-if="title" class="dropdown-title">{{ title }}:</p>
        <div class="c-dropdown" :class="dropdownStyle" @mouseover="hoverToggleMenu(1)" @mouseout="hoverToggleMenu(0)">
            <div class="dropdown-toggle j-dropdown-keep" @click="toggleMenu">
                <span v-html="value || modelValue"></span><span class="dropdown-caret j-dropdown-keep" :class="{ 'dropdown-caret-rotate': show }"></span>
            </div>
            <ul class="dropdown-menu j-sa-good-detail-sort" v-show="show" ref="dropdownMenu" @click.stop>
                <li class="j-dropdown-keep" v-for="(val, key, index) in list" @click="changeVal(val, key)" :data-sortindex="val.value">
                    <template v-if="showNumber">
                        <span>{{ Array.isArray(list) ? key + 1 : index + 1 }}.</span>
                    </template>
                    <template v-if="Array.isArray(list)">
                        <span v-html="val.title"></span>
                    </template>
                    <template v-else>
                        <span v-html="val"></span>
                    </template>
                    <div class="sublist-menu" v-if="val.sublist && activeSublist == key">
                        <li class="j-dropdown-keep" v-for="(subVal, subKey) in val.sublist" :key="subKey" @click="changeSubVal(subVal, subKey)">
                            <span v-html="subVal.title"></span>
                        </li>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import { isMobile } from 'public/src/pages/common/utils/index.js'

export default {
    props: {
        title: { default: '' },
        value: {},
        modelValue: {},
        list: { type: [Object, Array] },
        sm: { default: false },
        lg: { default: false },
        scroll: { default: false },
        showNumber: { default: false },
        hoverToggle: { default: false },
    },
    created: function() {},
    data: function() {
        return {
            show: false,
            touchstart: false,
            activeSublist: -1,
        }
    },
    computed: {
        dropdownStyle: function() {
            return {
                'c-dropdown-s': this.sm,
                'c-dropdown-l': this.lg,
                'dropdown-scroll': this.scroll
            }
        }
    },
    mounted: function() {
        var self = this
        document.addEventListener('click', function(e) {
            if (!e.target.classList.contains('j-dropdown-keep') && !navigator.userAgent.toLowerCase().match(/phone|pad|pod|iphone|ipod|ios|ipad|android/)) {
                self.show = false
            }
        })
    },
    methods: {
        changeVal: function(val, key) {
            if (val && val.sublist) {
                this.showSublist(key)
                this.$nextTick(() => {
                    this.$refs.dropdownMenu.scrollTop = 100
                })
            } else {
                this.activeSublist = -1
                this.$emit('input', Array.isArray(this.list) ? val.title : val)
                this.$emit('update:modelValue', Array.isArray(this.list) ? val.title : val)
                this.$emit('change', key, val)
                this.closeMenu()
            }
        },
        showSublist (index) {
            this.activeSublist = index
        },
        changeSubVal (val, key) {
            this.$emit('input', Array.isArray(this.list) ? val.title : val)
            this.$emit('change', key, val)
            this.closeMenu()
        },
        toggleMenu: function() {
            let self = this
            if (this.hoverToggle && !isMobile()) {
                return
            }
            console.log('click');
            if (isMobile()) {
              if (!this.touchstart) { // fix ipad
                this.show = !this.show
                self.touchstart = true
                setTimeout(() => {
                  self.touchstart = false
                }, 500)
              }
            } else {
              this.show = !this.show
            }
        },
        hoverToggleMenu: function(o) {
            if (!this.hoverToggle || isMobile()) {
                return
            }
            if (o) {
                this.show = true
            } else {
                this.show = false
            }
        },
        closeMenu () {
            if (isMobile()) {
              if (!this.touchstart) { // fix ipad
                this.show = false
                self.touchstart = true
                setTimeout(() => {
                  self.touchstart = false
                }, 500)
              }
            } else {
              this.show = false
            }
        }
    },
    emits: ['input', 'change'],
}
</script>
