import { SAlert } from '@shein-aidc/sui-alert'
import { SButton } from '@shein-aidc/sui-button'
import { SButtonGroup } from '@shein-aidc/sui-button-group'
import { SButtonGroupItem } from '@shein-aidc/sui-button-group-item'
import { STab } from '@shein-aidc/sui-tab'
import { STabItem } from '@shein-aidc/sui-tab-item'
import { SDialog } from '@shein-aidc/sui-dialog'
import { SRadio } from '@shein-aidc/sui-radio'
import { SRadioGroup } from '@shein-aidc/sui-radio-group'
import { SCheckbox } from '@shein-aidc/sui-checkbox'
import { SCheckboxGroup } from '@shein-aidc/sui-checkbox-group'
import { SDrawer } from '@shein-aidc/sui-drawer'
import { SPopover } from '@shein-aidc/sui-popover'
import { SMessage } from '@shein-aidc/sui-message'
import { SInput } from '@shein-aidc/sui-input'
import { SBreadcrumb } from '@shein-aidc/sui-breadcrumb'
import { SBreadcrumbItem } from '@shein-aidc/sui-breadcrumb-item'
import { SSelect } from '@shein-aidc/sui-select'
import { SSelectOption } from '@shein-aidc/sui-select-option'
import { SLabel } from '@shein-aidc/sui-label'
import { SPagination } from '@shein-aidc/sui-pagination'
import { SToast } from '@shein-aidc/sui-toast'
import { SInputTitle } from '@shein-aidc/sui-input-title'
import { STitle } from '@shein-aidc/sui-title'

import ADA from '../common/ada/index'
import adaModal from '../common/ada/adamodal'
import moveClick from '../common/ada/moveclick'

import modal from '../components/modal.vue'
import input from '../components/input.vue'
import dropdown from '../components/dropdown.vue'
import vip_medal from '../components/vip_medal.vue'
import risk_confirm from '../components/risk_confirm.vue'

import { useSaDirective } from '@shein-aidc/basis-sa-event-center'
import 'public/src/services/eventCenterV2'

/**
 * 注册全局 SUI 组件
 */
const _useLibsEnvInjection = (app) => {
  /* sui init */
  app.component(SAlert.name, SAlert)
  app.component(SButton.name, SButton)
  app.component(SButtonGroup.name, SButtonGroup)
  app.component(SButtonGroupItem.name, SButtonGroupItem)
  app.component(STab.name, STab)
  app.component(STabItem.name, STabItem)
  app.component(SDialog.name, SDialog)
  app.component(SRadio.name, SRadio)
  app.component(SRadioGroup.name, SRadioGroup)
  app.component(SCheckbox.name, SCheckbox)
  app.component(SCheckboxGroup.name, SCheckboxGroup)
  app.component(SDrawer.name, SDrawer)
  app.component(SPopover.name, SPopover)
  app.component(SMessage.name, SMessage)
  app.component(SInput.name, SInput)
  app.component(SBreadcrumb.name, SBreadcrumb)
  app.component(SBreadcrumbItem.name, SBreadcrumbItem)
  app.component(SSelect.name, SSelect)
  app.component(SSelectOption.name, SSelectOption)
  app.component(SLabel.name, SLabel)
  app.component(SPagination.name, SPagination)
  app.component(SInputTitle.name, SInputTitle)
  app.component(STitle.name, STitle)

  app.config.globalProperties.$message = SMessage
  app.config.globalProperties.$toast = SToast
  /* sui */
}

/**
 * 注册全局 replaceLang 过滤器
 */
const _useReplaceLangFilter = (app) => {
  app.config.globalProperties.$filters = {
    replaceLang(value, filter, rs) {
      if (!value) {
        return ''
      }
      value = value.toString()
      return value.replace(filter, rs)
    },
  }
}

/**
 * 注册全局 ADA 指令
 */
const _useAdaDirective = (app) => {
  /**
   * v-ada 指令: 添加 ADA类 所依赖的自定义属性 ( Dom结构有序场景, 无需使用 v-ada)
   * v-ada="{ level: (tabIndex to set), pos: (position(s) of the element(s)), adaption, subAdaption }"
   *
   * 参数对象接收level, pos
   * @param {Number} level 指定tabIndex
   * ( 为了方便统一管理/拓展level, 必须采用业务容器配置化维护组件level值 )
   * @param {Number|Array} pos 指定元素位置, 嵌套结构应按父级到子级次序依次传入元素下标
   * *( start at 0, 保证index连续, 针对条件渲染内容, 由使用方独立维护index, 参考列表item.vue)
   * @param {Number} adaption 下一层级最大聚焦个数(用于条件渲染场景)[由于0索引原因, 可选元素集必须被包含于一个固定根]
   * ( 下一层级有条件渲染元素时, 此元素讲尝试在范围内匹配首个找到的元素, 子层级互切范围亦取决于此值 )
   *
   * e.g.
   * 单层
   * v-ada="{ level: 2, pos: 0 }"
   * v-ada="{ level: 2, pos: [1] }"
   * 嵌套
   * v-ada="{ level: 4, pos: [0,5] }"
   * 条件
   * v-ada="{ level: 2, pos: 0, adaption: 2 }"
   */
  app.directive('ada', {
    getSSRProps: ADA.setSSRProps,
    mounted: ADA.setAdaData,
    updated: ADA.setAdaData,
  })

  app.directive('enterkey', {
    // 指令的定义
    beforeMount: function (el, binding, vnode) {
      const handler = binding?.value?.handler
      el.addEventListener('keydown', function (event) {
        if (event?.keyCode === 13 || event?.keyCode === 108) {
          if (handler) {
            handler()
          } else if (vnode?.data?.on?.click) {
            vnode.data.on.click(event)
          }
        }
      })
    },
  })

  app.directive('ada-modal', adaModal)

  /*
   * 只需父级使用v-ada-radio-group, radio项只要添加role="radio"
   * 上下左右键切换选中，当focus的radio没有选中，可用空格与回车选中
   * demo可参考orderList.vue
   */
  app.directive('ada-radio-group', moveClick)
}

/**
 * 全局变量
 */
const _useGlobalVariables = (app) => {
  app.config.globalProperties.$isServer = typeof window === 'undefined'
}

const setSheinProperty = (app, { GB_cssRight, IS_RW }) => {
  app.config.globalProperties.$SHEIN = {
    mir: GB_cssRight ? 'rtl' : 'ltr',
    brd: IS_RW ? 'rw' : 'sh',
  }
}

/**
 * 统一注册入口函数
 */
const registerBaseContext = (app, context) => {
  _useLibsEnvInjection(app)
  _useReplaceLangFilter(app)
  _useAdaDirective(app)
  useSaDirective(app)
  _useGlobalVariables(app)

  // UI 组件 - 弹窗提示
  app.component('ModalVue', modal)
  app.component('InputVue', input)
  app.component('DropdownVue', dropdown)

  // 业务组件
  app.component('VipMedal', vip_medal) // 会员勋章（包含会员入口展示逻辑）
  app.component('RiskConfirm', risk_confirm)

  // NOTICE: 大量 Vue 文件混乱使用到 v-lazy，无法一一在入口处注册 VueLazyload，因此在每个入口函数内注册
  if (typeof window !== 'undefined' && window !== null) {
    const { IS_RW = false, GB_cssRight = false } = gbCommonInfo
    // app.use(VueLazyload, {
    //   loading: LAZY_IMG,
    //   error: LAZY_IMG,
    //   throttleWait: 50,
    //   preLoad: 1.8,
    // })
    
    setSheinProperty(app, { GB_cssRight, IS_RW })
  } else {
    if (context) {
      const { IS_RW, GB_cssRight } = context
      setSheinProperty(app, { GB_cssRight, IS_RW })
    }
    // app.use(VueLazyload, {
    //   throttleWait: 50,
    //   preLoad: 1.8,
    // })
  }
}

export { registerBaseContext }
