<template>
  <transition name="vue-fade" appear @after-enter="transitionEndEvent">
    <div v-show="show" class="c-modal-wrap" :style="iscmadalstyle && cMadalStyle" v-ada-modal="adaModelObj">
      <div class="she-mask" :class="{ 'she-opacity0': isMaskHide }"></div>
      <div class="c-modal" @mousedown="clickMask($event)">
        <div ref="dialog" class="modal-dialog" :class="styleClass">
          <transition name="vue-slide-fade">
            <div
              v-show="show"
              class="modal-content"
              :style="{ background: background, padding: padding }"
            >
              <div class="modal-header">
                <slot name="headerLeft"></slot>
                <slot name="closer">
                  <i v-show="hasClose">
                    <Icon
                      v-enterkey
                      :aria-label="closeLabel"
                      tabindex="0"
                      role="button"
                      name="sui_icon_closed_14px_1"
                      class="svg-close she-close"
                      @click="cancel('icon')"
                    />
                  </i>
                </slot>
                <div class="modal-title">
                  <slot name="title" tabindex="0" ref="title"> </slot>
                </div>
              </div>
              <div class="modal-body" ref="body">
                <slot></slot>
              </div>
              <div class="modal-footer">
                <slot name="footer">
                  <div class="modal-btn">
                    <button
                      v-if="twobtn"
                      :class="cancelclass"
                      @click="cancel('btn')"
                    >
                      {{ canceltext }}
                    </button>

                    <button v-if="isloading" :class="okclass" @click="ok">
                      <div
                        v-if="loading"
                        class="la-ball-pulse la-ball-pulse-white"
                      >
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                      <div v-else>
                        {{ oktext }}
                      </div>
                    </button>

                    <button v-else :class="okclass" @click="ok">
                      {{ oktext }}
                    </button>
                  </div>
                </slot>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { Icon } from '@shein-aidc/icon-vue3'
let language = {}
let lang = 'en'

if (typeof window === 'object') {
  language = gbCommonInfo.language
  lang = gbCommonInfo.lang
}

let shareData = {
  counter: 0,
}

export default {
  components: {
    Icon,
  },
  props: {
    show: { type: Boolean, default: false },
    shoplook: { type: Boolean, default: false },
    smaller: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    large: { type: Boolean, default: false },
    xlarge: { type: Boolean, default: false },
    full: { type: Boolean, default: false },
    relativeheight: { type: Boolean, default: false },
    relativeWidthM: { type: Boolean, default: false },
    vertical: { type: Boolean, default: true },
    twobtn: { type: Boolean, default: true },
    isquickv: { type: Boolean, default: false },
    background: { type: String, default: '' },
    padding: { type: String, default: '' },
    // 为true时无法通过点击遮罩层关闭modal
    force: { type: Boolean, default: false },

    //是否有顶部关闭按钮
    hasClose: { type: Boolean, default: true },

    // 是否使用loading效果
    isloading: { type: Boolean, default: false },
    appendToBody: { type: Boolean, default: false },

    //弹窗是否为全屏
    fullscreen: { type: Boolean, default: false },

    oktext: {
      type: String,
      default: language.SHEIN_KEY_PC_15216,
    },
    canceltext: {
      type: String,
      default: language.SHEIN_KEY_PC_15748,
    },

    okclass: { type: String, default: 'she-btn-black' },
    cancelclass: { type: String, default: 'she-btn-white' },
    adaModel: { type: Object, default: () => null },
    transEndEvent: { type: Function, default: undefined },
    iscmadalstyle: { type: Boolean, default: false }
  },
  data() {
    return {
      closeLabel: language.SHEIN_KEY_PC_15639,
      isMaskHide: false,
      loading: false,
      scrollTop: 0
    }
  },
  computed: {
    styleClass() {
      return {
        'c-quickv': this.isquickv,
        'vertical-center': this.vertical,
        'modal-xs': this.smaller,
        'modal-sm': this.small,
        'modal-lg': this.large,
        'modal-xlg': this.xlarge,
        'modal-full': this.full,
        'modal-full-screen': this.fullscreen,
        'modal-shoplook': this.shoplook,
        'modal-relative-height': this.relativeheight,
        'modal-relative-width-m': this.relativeWidthM,
      }
    },
    cMadalStyle(){
      const elements = Array.from(document.querySelectorAll('*'));
      const zIndexes = elements.map(el => parseFloat(window.getComputedStyle(el).zIndex)).filter(zIndex => !isNaN(zIndex))
      const highestZIndex = Math.max(...zIndexes)
      const result = {
        'z-index': highestZIndex + 1
      }
      return result
    }
  },
  watch: {
    show(value) {
      var self = this

      if (value) {
        if (!this.vertical) {
          if (
            !(
              lang == 'ar' &&
              (!!window.ActiveXObject || 'ActiveXObject' in window)
            )
          ) {
            // IE浏览器, ar站打开弹窗会滚回头部
            document.body.className += ' modal-open'
          }

          if (lang == 'ar') {
            document.addEventListener('scroll', self.preventScroll)
          }
        }
        if (this.appendToBody) {
          document.body.appendChild(this.$el)
        }
      } else {
        window.setTimeout(function () {
          self.isMaskHide = false
          if (!self.vertical) {
            document.body.className = document.body.className.replace(
              /\s?modal-open/,
              ''
            )
            if (lang == 'ar') {
              document.removeEventListener('scroll', self.preventScroll)
            }
          }
        }, 500 || 0)
        if (this.appendToBody) {
          document.body.removeChild(this.$el)
        }
      }
    },
  },
  mounted() {
    if (!this.vertical) {
      var scroll_bar_width = 17
      var root = document.body
      var width_txt
      this.scrollTop = document.documentElement.scrollTop || document.body.scrollTop

      if (typeof window.innerWidth == 'number') {
        scroll_bar_width = window.innerWidth - root.clientWidth
      }

      width_txt =
        '.modal-open { border-right:' +
        scroll_bar_width +
        'px solid transparent }'

      if (document.all) {
        // document.createStyleSheet(url)
        window.style = width_txt
        document.createStyleSheet('javascript:style')
      } else {
        //document.createElement(style)
        var style = document.createElement('style')
        style.type = 'text/css'
        style.innerHTML = width_txt
        document.getElementsByTagName('HEAD').item(0).appendChild(style)
      }

      if (this.show) {
        if (
          !(
            lang == 'ar' &&
            (!!window.ActiveXObject || 'ActiveXObject' in window)
          )
        ) {
          // IE浏览器, ar站打开弹窗会滚回头部
          document.body.className += ' modal-open'
        }

        if (lang == 'ar') {
          document.addEventListener('scroll', this.preventScroll)
        }
      }
    }
  },
  unmounted() {
    if (!this.vertical) {
      document.body.className = document.body.className.replace(
        /\s?modal-open/,
        ''
      )
      if (lang == 'ar') {
        document.removeEventListener('scroll', this.preventScroll)
      }
    }
  },
  methods: {
    preventScroll() {
      document.documentElement.scrollTop = this.scrolTop
      document.body.scrollTop = this.scrolTop
    },
    adaModelObj() {
      const data = this.adaModel || {
        show: this.show,
        first: this.attempTrapFirstFocus,
      }
      // 等待动画结束再聚焦
      data.transition = true
      return data
    },
    transitionEndEvent() {
      try {
        if (this.transEndEvent) this.transEndEvent()
        else if (typeof this.adaModelObj.first === 'function') {
          this.adaModelObj.first()
        } else {
          this.adaModelObj.first && this.adaModelObj.first.focus()
        }
      } catch (e) {}
    },
    attempTrapFirstFocus() {
      if (this.$slots.title) {
        this.$slots.title[0] &&
          this.$slots.title[0].elm &&
          this.$slots.title[0].elm.focus()
      } else {
        this.adaModalInstance &&
          this.adaModalInstance.focusFirstDescendant(this.$refs.body)
      }
    },
    ok() {
      if (this.isloading) {
        if (this.loading) {
          return
        } else {
          this.loading = true
          this.$emit('ok')
        }
      } else {
        this.$emit('ok')
      }
    },
    cancel(opt) {
      this.$emit('cancel', opt)
    },
    clickMask(e) {
      if (!this.force && !this.isInTree(e.target)) {
        this.cancel('mask')
      }
    },
    isInTree(target) {
      return this.$refs.dialog.contains(target)
    },
  },
  emits: ['cancel', 'ok'],
}
</script>

<style lang="less">
.she-mask {
  z-index: @zindex-modal-background;
  position: fixed;
  top: 0;
  right: 0 /*rtl:ignore*/;
  bottom: 0;
  left: 0 /*rtl:ignore*/;
  background-color: fade(#222, 20%);
}
.svg-close {
  position: absolute;
  top: 8px;
  right: 8px;
}
</style>
